import Axios from "axios"

const CRM_SERVER = 'https://crm.thejosephcompany.co.za/api/v1/'
const CLOUD_FUNCTIONS_SERVER = 'https://us-central1-thejosephcompany.cloudfunctions.net'

export const FormService = {
    addContect: (
        name,
        phoneNumber,
        email,
        message,
        investmentAmount
    ) => {
        return Axios.get(`${CLOUD_FUNCTIONS_SERVER}/auth?accountKey=mif09ufmOIUnof093fpokw908`).then(result => {
            const token = result.data.token

            return Axios.post(`${CLOUD_FUNCTIONS_SERVER}/sendForm`, JSON.stringify({
                name: name,
                phoneNumber: phoneNumber,
                email: email,
                message: message,
                investmentAmount: investmentAmount
            }), {
                headers: {
                    'Authorization': 'Base ' + token,
                    'Content-Type': 'application/json'
                }
            }) 
        })
        
    }
}